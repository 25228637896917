import * as React from 'react';
import { IPersonaSharedProps,IPersonaStyles,IPersonaProps, Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Label, ILabelStyles } from '@fluentui/react/lib/Label';
import { createTheme } from '@fluentui/react';
import './Persona.css'

const myTheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#0b0b0b',
    neutralLighter: '#151515',
    neutralLight: '#252525',
    neutralQuaternaryAlt: '#2f2f2f',
    neutralQuaternary: '#373737',
    neutralTertiaryAlt: '#595959',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#000000',
  }});

// Styles definition
const stackStyles: IStackStyles = {
    root: {
      background: DefaultPalette.themePrimary,
      padding:0,
      marginTop:-8,
      marginLeft:-8,
      marginRight:-8,
    },
  };
  const stackItemStyles: IStackItemStyles = {
    root: {
      color: DefaultPalette.white,
      padding: 5,
    },
  };
  
  // Tokens definition
  const containerStackTokens: IStackTokens = { childrenGap: 5 };
  const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 1,
  };
  const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 1,
  };
  const clickableStackTokens: IStackTokens = {
    padding: 10,
  };
  
  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const itemStyles: React.CSSProperties = {
    alignItems: 'center',
    background: DefaultPalette.themePrimary,
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  };

  const sqladvStyle: React.CSSProperties = {
    color: DefaultPalette.white,
    marginTop:8,
    marginLeft:16
  };
  
  const lblSQLADV:ILabelStyles = {
    root: {
      color: DefaultPalette.white,
      marginLeft: 10,
      marginTop: 5,
      marginBottom: 8
    } 
  }

  const sharedPersonaProps: IPersonaProps = {
    size: PersonaSize.size100,
    styles: {
      root: {
        width: 300,
        margin: 5
      },
    },
  };

export const PersonaBasicExample: React.FunctionComponent = () => {
  const [renderDetails, updateRenderDetails] = React.useState(true);
  const onChange = (ev: unknown, checked: boolean | undefined) => {
    updateRenderDetails(!!checked);
  };

  const examplePersona: IPersonaSharedProps = {
    imageInitials: 'ICSA',
    text: 'GERENTE DE TI',
    secondaryText: '',
    initialsTextColor:DefaultPalette.white,
    initialsColor:DefaultPalette.purple
    
  };

  return (   
     <Stack horizontal horizontalAlign="space-between" disableShrink styles={stackStyles} tokens={horizontalGapStackTokens}>
                <Label styles={lblSQLADV}>SQL ADVANCED | PORTAL DE CLIENTES</Label>
                {/*
                <Persona
        className='personas'
        {...examplePersona}
        size={PersonaSize.size40}
        hidePersonaDetails={!renderDetails}
        imageAlt="Annie Lindqvist, no presence detected"
        
      />
                */}
   </Stack>
  

    /*
      <>
    <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
    <Stack.Item align="auto" styles={stackItemStyles}>
      <span>Auto-aligned item</span>
    </Stack.Item>
    <Stack.Item align="stretch" styles={stackItemStyles}>
      <span>Stretch-aligned item</span>
    </Stack.Item>
    <Stack.Item align="baseline" styles={stackItemStyles}>
      <span>Baseline-aligned item</span>
    </Stack.Item>
    <Stack.Item align="start" styles={stackItemStyles}>
      <span>Start-aligned item</span>
    </Stack.Item>
    <Stack.Item align="center" styles={stackItemStyles}>
      <span>Center-aligned item</span>
    </Stack.Item>
    <Stack.Item align="end" styles={stackItemStyles}>
      <span>End-aligned item</span>
                <Persona
                {...examplePersona}
                size={PersonaSize.size40}
                presence={PersonaPresence.away}
                hidePersonaDetails={!renderDetails}
                imageAlt="Annie Lindqvist, status is away"
                />
    </Stack.Item>
  </Stack>
                <Persona
                {...examplePersona}
                size={PersonaSize.size40}
                presence={PersonaPresence.away}
                hidePersonaDetails={!renderDetails}
                imageAlt="Annie Lindqvist, status is away"
                />
      
      </>          
      */
  );
};

export default PersonaBasicExample;