import * as React from 'react';
import { IStyleSet, ILabelStyles, Pivot, PivotItem } from '@fluentui/react';
import { Stack,IStackItemStyles,DefaultPalette,IStackStyles,IStackTokens } from '@fluentui/react';
import ButtonCompoundExample from './Boton.tsx';
import { CompoundButton,IButtonStyles } from '@fluentui/react/lib/Button';
import { initializeIcons } from '@fluentui/font-icons-mdl2';


initializeIcons();


//button
export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { marginTop: 6 },
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    background: DefaultPalette.white,
    marginTop:10
  },
};

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const estiloBoton:IButtonStyles = {
  root: {
    marginRight: 10,
    width:500
  }
}

const powerBiLogo: IIconProps = { iconName: 'PowerBILogo' };

export const PivotBasicExample: React.FunctionComponent = () => {
  return (
    <Pivot aria-label="Reportes">
      <PivotItem
        headerText="Reportes GP"
        headerButtonProps={{
          'data-order': 1,
          'data-title': 'My Files Title',
        }}
      >
        <Stack horizontal horizontalAlign="center" disableShrink styles={stackStyles} tokens={horizontalGapStackTokens}>
        <CompoundButton href="https://app.powerbi.com/links/dnMPH5GMM3?ctid=f6cadc1e-4e5c-4032-8ba9-90427d74f03e&pbi_source=linkShare&bookmarkGuid=5670cf36-78c2-4e14-a57a-2003871def73" target="_blank" primary secondaryText="Reporte de facturas de proveedores del area de TI para todas las compañias" iconProps={powerBiLogo} styles={estiloBoton}>
        Gastos proveedores TI
        </CompoundButton>
        <CompoundButton href="https://app.powerbi.com/links/MJrQ8Z6dpv?ctid=f6cadc1e-4e5c-4032-8ba9-90427d74f03e&pbi_source=linkShare" target="_blank" primary secondaryText="Muestra eventos de seguridad capturados por PANDA" iconProps={powerBiLogo} styles={estiloBoton}>
        PANDA Eventos
        </CompoundButton>
        <CompoundButton href="https://app.powerbi.com/links/T-4EWVGllQ?ctid=f6cadc1e-4e5c-4032-8ba9-90427d74f03e&pbi_source=linkShare" target="_blank" primary secondaryText="Eventos que generan los usuarios de GP" iconProps={powerBiLogo} styles={estiloBoton}>
        GP Eventos Usuarios
        </CompoundButton>
      </Stack>
      </PivotItem>
      <PivotItem headerText="Reportes Agricola">
      <Stack horizontal horizontalAlign="center" disableShrink styles={stackStyles} tokens={horizontalGapStackTokens}>
      <CompoundButton primary secondaryText="Reportes relacionados con agricola" iconProps={powerBiLogo} styles={estiloBoton}>
        Reporte Agri 1
        </CompoundButton>
        <CompoundButton primary secondaryText="Reportes relacionados con agricola" iconProps={powerBiLogo} styles={estiloBoton}>
        Reporte Agri 2
        </CompoundButton>
        <CompoundButton primary secondaryText="Reportes relacionados con agricola" iconProps={powerBiLogo} styles={estiloBoton}>
        Reporte Agri 3
        </CompoundButton>
      </Stack>
      </PivotItem>
    </Pivot>
  );
};

export default PivotBasicExample;
