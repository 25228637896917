import './App.css';
import PersonaBasicExample from './Persona.tsx';
import PivotBasicExample from './NavReportes.tsx';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated
} from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { SpinnerBasicExample } from './Progress.tsx';


function App() {

  const isAuthenticated = useIsAuthenticated();
const { instance, inProgress } = useMsal();

if (inProgress === InteractionStatus.None && !isAuthenticated) {
  instance.loginRedirect(loginRequest);
}

  return (
    <div className="App">
      <AuthenticatedTemplate> 
          <PersonaBasicExample/>
          <PivotBasicExample/>           
      </AuthenticatedTemplate>
  
        <UnauthenticatedTemplate>
          <br />
          <SpinnerBasicExample/>
        </UnauthenticatedTemplate>
    </div>
  );
}



{/*
  return (
    <div className="App">

          <PersonaBasicExample/>
          <PivotBasicExample/>           
    
    </div>
  );
}
*/}


export default App;
